/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: 'NotoSansKR', Sans-Serif !important;
  font-weight: 400;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

ul, ol {
  list-style-type: none;
}

input {
  box-sizing: border-box;
}

/* PageNotFound */
.page-not-found {}
.page-not-found svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -400px;
}
.page-not-found .message-box {
  height: 200px;
  width: 380px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: 50px;
  font-weight: 300;
}
.page-not-found .message-box h1 {
  font-size: 60px;
  line-height: 46px;
  margin-bottom: 40px;
}
.page-not-found .buttons-con .action-link-wrap {
  margin-top: 40px;
}
.page-not-found .buttons-con .action-link-wrap a {
  background: #6fd400;
  padding: 8px 25px;
  border-radius: 4px;
  color: #FFF;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px
}
.page-not-found .buttons-con .action-link-wrap a:hover {
  background: #5A5C6C;
  color: #fff;
}

.page-not-found #Polygon-1 , .page-not-found #Polygon-2 , .page-not-found #Polygon-3 , .page-not-found #Polygon-4 , .page-not-found #Polygon-4, .page-not-found #Polygon-5 {
  animation: float 1s infinite ease-in-out alternate;
}
.page-not-found #Polygon-2 {
  animation-delay: .2s;
}
.page-not-found #Polygon-3 {
  animation-delay: .4s;
}
.page-not-found #Polygon-4 {
  animation-delay: .6s;
}
.page-not-found #Polygon-5 {
  animation-delay: .8s;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}
@media (max-width: 450px) {
  .page-not-found svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -190px;
  }
  .page-not-found .message-box {
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -190px;
    text-align: center;
  }
}

/*@media print {*/
/*  @page { margin: 0; }*/
/*  body { margin: 0 1.6cm; }*/
/*}*/
