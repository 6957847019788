@charset "UTF-8";
@font-face {
	font-family: 'NotoSansKR';
	src: url('../fonts/NotoSansKR/notokr-demilight.woff') format('woff'),
	url('../fonts/NotoSansKR/notokr-demilight.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'NotoSansKR';
	src: url('../fonts/NotoSansKR/notokr-regular.woff') format('woff'),
	url('../fonts/NotoSansKR/notokr-regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'NotoSansKR';
	src: url('../fonts/NotoSansKR/notokr-medium.woff') format('woff'),
	url('../fonts/NotoSansKR/notokr-medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'NotoSansKR';
	src: url('../fonts/NotoSansKR/notokr-bold.woff') format('woff'),
	url('../fonts/NotoSansKR/notokr-bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}